import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Ymir from '../src/components/Ymir/Ymir';
import { LIVE_BACKEND_URL } from '../src/helpers/liveBackend';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import Layout from '../src/components/Layout/Layout';
import './contacto.scss';

/**
 * Retrieves input data from a form and returns it as a JSON object.
 * @param  {HTMLFormControlsCollection} elements  the form elements
 * @return {Object}                               form data as an object literal
 */
const formToJSON = (elements) =>
  [].reduce.call(
    elements,
    (data, element) => {
      data[element.name] =
        element.type === 'checkbox' ? (element.checked ? '1' : '0') : element.value;
      return data;
    },
    {}
  );

export const GatsbyQuery = () => graphql`
  query ContactQuery {
    contactoTitleImage {
      alt
      localImage {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

const STATUS_INIT = 1;
const STATUS_SUBMITTING = 2;
const STATUS_DONE = 3;
const STATUS_ERROR = 4;

const MAX_CHARS = 850;

const validateInformation = (locale, contact) => {
  const errors = {};

  if (!contact.name) {
    errors.name = tt('Nombre no puede estar vacío', locale);
  }
  if (!contact.first_surname) {
    errors.first_surname = tt('Primer Apellido no puede estar vacío', locale);
  }
  if (!contact.second_surname) {
    errors.second_surname = tt('Segundo Apellido no puede estar vacío', locale);
  }
  if (!contact.email) {
    errors.email = tt('email no puede estar en blanco', locale);
  }
  if (!contact.gender) {
    errors.gender = tt('Selecciona un género', locale);
  }
  if (typeof contact.email !== 'undefined' && !contact.email === false) {
    const lastAtPos = contact.email.lastIndexOf('@');
    const lastDotPos = contact.email.lastIndexOf('.');

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        contact.email.indexOf('@@') === -1 &&
        lastDotPos > 2 &&
        contact.email.length - lastDotPos > 2
      )
    ) {
      errors.email = tt('Email no válido', locale);
    }
  }
  if (!(contact.email_confirmation === contact.email)) {
    errors.email_confirmation = tt('El mail debe coincidir', locale);
  }
  if (/[^+\d]/g.test(contact.phone) || !contact.phone) {
    errors.phone = tt('El teléfono en números', locale);
  }
  if (/[^+\d]/g.test(contact.mobile) || !contact.mobile) {
    errors.mobile = tt('El movil en números', locale);
  }
  if (!contact.type_of_inquiry) {
    errors.type_of_inquiry = tt('Debes especificar una consulta', locale);
  }
  if (!contact.hotel) {
    errors.hotel = tt('Debes especificar un hotel', locale);
  }
  if (!contact.comment) {
    errors.comment = tt('Debes poner una consulta', locale);
  }
  if (contact.lopd_acepted !== '1') {
    errors.lopd_acepted = tt('Debes aceptar el aviso legal', locale);
  }

  return errors;
};

const ContactForm = ({ onSubmit, status, errors, locale }) => {
  const [commentLength, setCommentLength] = useState(0);

  const destination =
    locale === 'es' ? `${LIVE_BACKEND_URL}contacto` : `${LIVE_BACKEND_URL}${locale}/contact`;

  return (
    <form action={destination} onSubmit={onSubmit} acceptCharset="utf-8" method="POST">
      <input type="hidden" name="subtitle" value="" />
      <div className="form-container">
        <span className="title"> {tt('FORMULARIO DE CONTACTO', locale)} </span>
        <div className="fullWi">
          <span className="title-span">{tt('*Nombre:', locale)}</span>
          <input type="text" name="name" className="input-paw" />
          <span style={{ color: 'red' }}>{errors.name}</span>
        </div>
        <div className="halfWi">
          <div className="input-container">
            <span className="title-span">{tt('*Primer Apellido:', locale)}</span>
            <input type="text" name="first_surname" className="input-paw" />
            <span style={{ color: 'red' }}>{errors.first_surname}</span>
          </div>
          <div className="input-container">
            <span className="title-span">{tt('*Segundo Apellido:', locale)}</span>
            <input type="text" name="second_surname" className="input-paw" />
            <span style={{ color: 'red' }}>{errors.second_surname}</span>
          </div>
        </div>
        <div className="halfWi">
          <div className="input-container">
            <span className="title-span">{tt('*Correo Electrónico:', locale)}</span>
            <input type="text" name="email" className="input-paw" />
            <span style={{ color: 'red' }}>{errors.email}</span>
          </div>
          <div className="input-container">
            <span className="title-span">{tt('Confirmar Correo Electrónico:', locale)}</span>
            <input type="text" name="email_confirmation" className="input-paw" />
            <span style={{ color: 'red' }}>{errors.email_confirmation}</span>
          </div>
        </div>
        <div className="threWi">
          <div className="input-container">
            <span className="title-span">{tt('*Teléfono', locale)}</span>
            <input type="text" name="phone" className="input-paw" />
            <span style={{ color: 'red' }}>{errors.phone}</span>
          </div>
          <div className="input-container">
            <span className="title-span">{tt('*Móvil', locale)}</span>
            <input type="text" name="mobile" className="input-paw" />
            <span style={{ color: 'red' }}>{errors.mobile}</span>
          </div>
          <div className="input-container">
            <span className="title-span">{tt('Sexo', locale)}</span>
            <select
              name="gender"
              className="input-paw"
              placeholder={tt('Selecciona una opción', locale)}
            >
              <option value="male">{tt('Hombre', locale)}</option>
              <option value="female">{tt('Mujer', locale)}</option>
            </select>
            <span style={{ color: 'red' }}>{errors.gender}</span>
          </div>
        </div>
        <div className="halfWi">
          <div className="input-container">
            <span className="title-span">{tt('*Tipo de Consulta', locale)}</span>
            <select
              name="type_of_inquiry"
              className="input-paw"
              placeholder={tt('Selecciona una opción', locale)}
            >
              <option value="general">
                {tt('Información general de parque y hoteles', locale)}
              </option>
              <option value="ticket_purchase">{tt('Compra de Entradas', locale)}</option>
              <option value="room_reservation">{tt('Reserva de Hoteles', locale)}</option>
              <option value="feedback">{tt('Sugerencias y Feedback website', locale)}</option>
              <option value="lost_and_found_goods_hotel">
                {tt('Objetos Perdidos en Hoteles', locale)}
              </option>
              <option value="lost_and_found_goods_park">
                {tt('Objetos Perdidos en Parques', locale)}
              </option>
              <option value="hotel_claims">
                {tt('Sugerencias y Reclamaciones en Hoteles', locale)}
              </option>
              <option value="park_claims">
                {tt('Sugerencias y Reclamaciones en Parques', locale)}
              </option>
              <option value="photography">{tt('Fotografía', locale)}</option>
            </select>
            <span style={{ color: 'red' }}>{errors.type_of_inquiry}</span>
          </div>
          <input type="hidden" name="subtitle" />
          <div className="input-container">
            <span className="title-span">{tt('Hotel', locale)}</span>
            <select name="hotel" className="input-paw">
              <option value="no-hotel" />
              <option value="hotel-paw">{tt('Hotel PortAventura', locale)}</option>
              <option value="hotel-caribe">{tt('Hotel Caribe', locale)}</option>
              <option value="hotel-elpaso">{tt('Hotel El Paso', locale)}</option>
              <option value="hotel-mansion">{tt('Hotel Mansión de Lucy', locale)}</option>
              <option value="hotel-gr">{tt('Hotel Gold River', locale)}</option>
              <option value="hotel-colorado">{tt('Hotel Colorado Creek', locale)}</option>
            </select>
            <span style={{ color: 'red' }}>{errors.hotel}</span>
          </div>
        </div>
        <div className="fullWi">
          <div className="input-container">
            <span className={`title-span ${commentLength >= MAX_CHARS ? 'isRed' : ''}`}>
              {tt('Consulta/Comentario', locale)} ({commentLength}/{MAX_CHARS})
            </span>
            <textarea
              name="comment"
              onChange={(e) => {
                setCommentLength(e.target.value.length);
                e.preventDefault();
              }}
              maxLength={MAX_CHARS + 1}
              className="input-paw text-area"
            />
            <span style={{ color: 'red' }}>{errors.comment}</span>
          </div>
        </div>

        <div className="legal-copy-container">
          <div
            dangerouslySetInnerHTML={{
              __html: tt(
                '<p> De conformidad con el RGPD 2016/679, así como con la LOPDGDD 3/2018 de 5 de diciembre, le informamos que sus datos personales serán tratados por PORT AVENTURA ENTERTAINMENT, S.A.U., como responsable del tratamiento, con la finalidad de gestionar la consulta, sugerencia, queja o reclamación planteada. Puede consultar las bases legitimadoras del tratamiento de sus datos, los derechos que le asisten, así como cualquier otra información adicional relativa al tratamiento de sus datos, <a href=" https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/016/378/original/ES_def.pdf" target="_blank"> aqui </a>.</p>',
                locale
              ),
            }}
          />
          {/* <p>
            {tt(
              'De conformidad con el RGPD 2016/679, así como con la LOPDGDD 3/2018 de 5 de diciembre, le informamos que sus datos personales serán tratados por PORT AVENTURA ENTERTAINMENT, S.A.U., como responsable del tratamiento, con la finalidad de gestionar la consulta, sugerencia, queja o reclamación planteada. Puede consultar las bases legitimadoras del tratamiento de sus datos, los derechos que le asisten, así como cualquier otra información adicional relativa al tratamiento de sus datos, aqui.',
              locale
            )}
          </p> */}
        </div>
        <div className="fullWi">
          <div className="check-container">
            <div className="checkbox-container less">
              <label htmlFor="lopd_acepted">
                <input type="checkbox" name="lopd_acepted" id="lopd_acepted" value="1" />
                {tt('He leído y acepto el aviso legal', locale)}
              </label>
              <span style={{ color: 'red' }}>
                <p>{errors.lopd_acepted}</p>
              </span>
            </div>
            <div className="checkbox-container">
              <p>{tt('Los datos indicados con asterisco son obligatorios.', locale)}</p>
            </div>
          </div>
        </div>
        <div className="button-container">
          {status === STATUS_INIT ? (
            <button type="submit" value="Continuar" className="button is-rounded alone-orange">
              {' '}
              {tt('Continuar', locale)}
            </button>
          ) : (
            <a className="button is-rounded alone-orange is-loading">{tt('Enviando...', locale)}</a>
          )}
        </div>
      </div>
    </form>
  );
};

const ContactSent = ({ locale }) => (
  <div className="success-container">
    <div className="message-container">
      {tt('Gracias por contactar con PortAventura. En breve les responderemos.', locale)}
    </div>
  </div>
);

const ContactError = ({ locale }) => (
  <div className="error-container">
    <div className="message-container">
      {tt(
        'No hemos podido recibir la información en este momento. Inténtalo de nuevo más tarde.',
        locale
      )}
    </div>
  </div>
);

const submitForm = (action, input) => {
  const data = { ...input };
  delete data[''];

  return fetch(action, {
    method: 'POST',
    body: JSON.stringify({ contact: data }),
    redirect: 'manual',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
};

const ContactFormContainer = ({ locale }) => {
  const [status, setStatus] = useState(STATUS_INIT);
  const [errors, setErrors] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    if (status !== STATUS_INIT) {
      return;
    }
    const data = formToJSON(e.target);
    const newErrors = validateInformation(locale, data);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) {
      return;
    }
    setStatus(STATUS_SUBMITTING);
    submitForm(e.currentTarget.action, data).then((response) => {
      setStatus(response.type === 'opaqueredirect' ? STATUS_DONE : STATUS_ERROR);
    });
  };

  switch (status) {
    case STATUS_DONE:
      return <ContactSent locale={locale} />;
    case STATUS_ERROR:
      return <ContactError locale={locale} />;
    default:
      return <ContactForm status={status} errors={errors} onSubmit={onSubmit} locale={locale} />;
  }
};

const Contacto = ({ data, pageContext: { locale, pageName, url } }) => {
  const heimdallData = {
    name: tt('CONTACTO', locale),
    image: {
      alt: data.contactoTitleImage.alt,
      url: data.contactoTitleImage.localImage.childImageSharp.fluid,
    },
  };

  const ymirData = {
    cards: [
      {
        title: tt('HORARIOS Y CALENDARIO', locale),
        buttonCard: tt('DESCUBRIR', locale),
        link: tt('/horarios-calendario', locale),
        icon: 'calendar',
      },
      {
        title: tt('CÓMO LLEGAR AL RESORT', locale),
        buttonCard: tt('DESCUBRIR', locale),
        link: tt('/destino/planificar/como-llegar-a-portaventura', locale),
        icon: 'LOCATION',
      },
      {
        title: tt('DESCUENTOS EN ENTRADAS ONLINE', locale),
        buttonCard: tt('DESCUBRIR', locale),
        link: tt('/promociones', locale),
        icon: 'Methodofpayment',
      },
    ],
  };
  return (
    <Layout layoutFromOld locale={locale} pageName={pageName}>
      <SEO title="Contacto" description="Contactar con Portaventura" pathname={url} />
      <div className="is-fullWidth">
        <Heimdall data={heimdallData} />
        <div className="general-index">
          <div className="contacto-container">
            <ContactFormContainer locale={locale} />
            <Ymir data={ymirData} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

Contacto.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default Contacto;
